//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Menu from '@/frontend/components/layout/menu'
import SwitchUser from '@/components/common/switch-user'
import {mapState} from 'vuex'
import InitialCountrySelector from "@/frontend/components/common/country-redirect/initial-country-selector";
import CountrySelectorDialog from "@/frontend/components/common/country-redirect/country-selector-dialog";

export default {
  name: 'Header',
  components: {
    CountrySelectorDialog,
    InitialCountrySelector,
    Menu,
    SwitchUser,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  data: (vm) => {
    return {
      content: {},
      navigationMenu: [],
      keyword: null,
      showSwitchUserDialog: false,
      leftMenuItems: [],
      countries: [],
      correctUserCountry: "",
      selectedCountry: "",
      firstCountrySelector: false,
      countrySelector: false,
      headerImages: {},
    }
  },
  computed: {
    ...mapState('shoppingCart', ['cartItemCount']),
    header_menus() {
      return [
        // {
        //   label: 'FIND NEAREST RETAILER',
        //   link: 'test1',
        // },
        {
          label: 'Email us',
          link: 'contact-us',
        },
        {
          label: this.userMenuItem.label,
          link: this.userMenuItem.link,
        },
        {
          label: 'Switch user',
          link: 'switch-user',
        },
        {
          label: 'Shopping cart (' + this.cartItemCount + ')',
          link: 'shopping-cart',
        },
      ]
    },
    userMenuItem() {
      const accountMenuLink = {}
      if (this.$auth.loggedIn) {
        accountMenuLink.label = this.$auth.user
          ? (this.$auth.user.isCompanyLocation ? this.$auth.user.fullname : this.$auth.user.companyName)
          : 'My account'
        accountMenuLink.link = 'my-dashboard'
      } else {
        accountMenuLink.label = 'Login'
        accountMenuLink.link = 'user-login'
      }
      return accountMenuLink
    },
  },
  async created() {
    try {
      this.content = (await this.$axios.get(`/contents/header-menu`)).data
      this.headerImages = (await this.$axios.get(`/contents/header`)).data
    }catch (e){
      this.$toast.error("Something went wrong while loading the header content!")
    }
    this.getCollectionsMenu()
    if (this.cartItemCount <= 0 && process.client) {
      await this.$store.dispatch('shoppingCart/frontendGetBySessionId')
    }
  },
  async mounted() {
    this.countries = (await this.$axios.get(`/labellistitems/COUNTRIES/items`)).data
    this.addGeoTargetlyScript();

    if(localStorage.getItem('selectedCountry')) {
      let selected = JSON.parse(localStorage.getItem('selectedCountry'));
      this.selectedCountry = selected.code;
    }
  },
  methods: {
    showItem(menuItemLink) {
      switch (menuItemLink) {
        case 'advertising-materials':
          return false
        case 'switch-user':
          return this.isSuperUser()
        default:
          return true
      }
    },
    setLeftMenuItems() {
      this.navigationMenu.forEach((menuItem) => {
        this.leftMenuItems.push({
          label: menuItem.menuLabel.fieldValue.value,
          link: menuItem.menuLink.fieldValue.value,
        })
      })
      this.leftMenuItems = [...this.leftMenuItems, ...this.header_menus]
    },
    checkSelectedLink(link) {
      if (link == 'switch-user') {
        this.closeNav()
        this.openSwitchUserDialog()
      }
    },
    openNav() {
      document.getElementById('mySidenav').style.width = '250px'
    },

    getCollectionsMenu() {
      const vueObj = {}
      Object.assign(vueObj, this.content.details)
      this.navigationMenu = vueObj.items
      this.setLeftMenuItems()
      return vueObj.items
    },

    closeNav() {
      document.getElementById('mySidenav').style.width = '0'
    },
    search() {
      if (this.keyword != null && this.keyword != '') {
        this.$router.push({
          path: '/catalog-search',
          query: {keyword: this.keyword},
        })
      }
    },
    resetSubmit(e) {
      e.preventDefault()
    },
    isSuperUser() {
      return this.$auth.user ? this.$auth.user.userTypeCode === 'SUPER' : false
    },
    hasSwitchUserOption(menuLink) {
      return (
        menuLink !== 'switch-user' ||
        (menuLink === 'switch-user' && this.isSuperUser())
      )
    },
    openSwitchUserDialog() {
      this.showSwitchUserDialog = true
    },
    closeSwitchUserDialog() {
      this.showSwitchUserDialog = false
    },
    userSwitchedHandler() {
      this.showSwitchUserDialog = false
      this.$router.push('my-dashboard')
    },
    changeCountry(code){
      this.selectedCountry = code;
      let selectedSiteCountry = this.countries.find(x => x.code === code);

      if (selectedSiteCountry){

        localStorage.setItem("selectedCountry", JSON.stringify(selectedSiteCountry));
        window.location.href = selectedSiteCountry.description  + "?redirected-from=" + selectedSiteCountry.code;

      }
    },
    showCountrySelector(){
      this.firstCountrySelector = false;

      if (window.geotargetly_country_code() !== this.selectedCountry){

        let otherSiteCountry = this.countries.find(x => x.code === window.geotargetly_country_code());

        if (otherSiteCountry){
          this.correctUserCountry = otherSiteCountry
          this.countrySelector = true;
        }
      }
    },
    addGeoTargetlyScript(){
      let geotargetly = document.querySelector("#geo-targetly")
      if (geotargetly){
        geotargetly.remove()
      }
      geotargetly = document.createElement('script');
      geotargetly.setAttribute('type','text/javascript');
      geotargetly.setAttribute('id','geo-targetly');
      geotargetly.async = true;
      geotargetly.defer = true;
      geotargetly.setAttribute('src', 'https://g1386590346.co/gl?id=' + this.$config.geoTargetlyKey + '&refurl='+window.location.origin+'&winurl='+encodeURIComponent(window.location));
      geotargetly.onload = () =>  this.geoLocationSelector()
      document.getElementsByTagName('head')[0].appendChild(geotargetly);

    },
     geoLocationSelector(){
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if (this.countries && this.countries.length > 0){
        let siteCountry = this.countries.find(x => x.description === window.location.origin);
        if (siteCountry){
          this.selectedCountry = siteCountry.code;

          if (urlParams.has('redirected-from')){

            localStorage.setItem("selectedCountry", JSON.stringify(siteCountry));
            localStorage.setItem("userCountry", JSON.stringify(window.geotargetly_country_code()));

            if (urlParams.has('initial-redirect') ){
              this.showCountrySelector();
            }

          }
          else if(localStorage.getItem('selectedCountry')) {
            let goTo = JSON.parse(localStorage.getItem('selectedCountry'));
            if (goTo.description !== window.location.origin){
              window.location.href = goTo.description;
            }
            else {
              this.selectedCountry = goTo.code;
            }
          }else{
            this.firstCountrySelector = true;
          }

          this.checkUserLocation()

        }
      }
    },
    checkUserLocation(){
      if (localStorage.getItem('userCountry')){
        let userCountry = JSON.parse(localStorage.getItem('userCountry'));

        if (userCountry !== window.geotargetly_country_code()){
          localStorage.setItem("userCountry", JSON.stringify(window.geotargetly_country_code()));
          this.showCountrySelector()
        }
      }
    }
  },
}
